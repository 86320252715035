.source-card {
  height: 8rem;
  overflow: hidden !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subtype-container {
  width: 80%;
  margin: auto;
}
.title-on-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.activity-card {
  position: relative;
  text-align: center;
  color: black;
  display: flex;
  background-color: white;
  width: 100%;
  margin: 2.5%;
  padding: 0.5rem;
  align-items: center;
  border-radius: 5px;
}

.activity-image-container {
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activity-image {
  object-fit: cover;
  width: 60px;
  height: 60px;
}
.activity-thumbnail-details-container {
  margin-left: 5%;
}

.corner-box {
  align-self: flex-start;
  content: "";
  position: absolute;
  z-index: 49;
  right: 1%;
  margin: -20px;
  width: 70px;
  height: 40px;
  background-color: #277ddb;
  transform: rotate(33deg);
  display: flex;
  justify-content: center;
}
.resource-flag {
  bottom: 0;
  transform: rotate(-33deg);
  color: white;
  padding-top: 2rem;
  align-self: flex-end;
  font-size: smaller;
}

.grey-bar {
  width: 80%;
  margin: auto;
  border-top: 1px solid rgba(128, 128, 128, 0.411);
}
.activity-thumbnail-details-container {
  width: 80%;
  white-space: wrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.activity-description-preview {
  max-height: 2.5rem;
  max-width: 80%;
  font-size: small;
  text-overflow: ellipsis;
}

.add-tint {
  filter: brightness(70%);
}
