.custom-max {
  max-height: 200px;
}
.small-custom-max {
  max-height: 40px;
}
.add-fade-in {
  animation-name: fadeIn;
  animation: fadeIn 1s;
  overflow: hidden;
}

.add-fade-out {
  animation-name: fadeOut;
  animation: fadeOut 1s;
}

@keyframes fadeIn {
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes fadeOut {
  0% {
    transform: translateY(-65%);
  }
  100% {
    transform: translateY(0%);
  }
}
