@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");

/* Removes InfoWindow padding */
.gm-style-iw-c {
  padding: 0 !important;
}

div.gm-style-iw-d {
  overflow: hidden !important;
}

/* Removes InfoWindow "X" button */
.gm-ui-hover-effect {
  display: none !important;
}

.resource-header {
  color: #277ddb;
  font-weight: bold;
  padding-left: 2.5rem;
}

.filters-divider {
  width: 96%;
  align-self: center;
  animation: draw-line 2s linear;
  border-top: solid 1px rgb(206, 204, 204);
}
.welcome-divider {
  width: 96%;
  align-self: center;
  animation: draw-line2 2s linear;
  border-top: solid 1px rgb(37, 99, 235);
}

.filter-options {
  max-height: 400px;
  overflow: scroll;
}

.loader-holder {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 900px;
}
.custom-padding-bottom {
  padding-bottom: 300px;
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
.add-pointer-hover:hover {
  cursor: pointer;
}
.new-info {
  background: rgba(0, 0, 0, 0.589) !important;
  /* border: 1px solid #43a3e2;
  border-left: 6px solid #43a3e2; */
  /* border-radius: 10px !important; */
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}
.Toastify__toast-container {
  right: 0 !important;
  padding-right: 0 !important;
  margin-right: 0 !important;
}
.new-info-body {
  color: white !important;
  font-weight: 500;
}
.new-warn {
  background: rgba(0, 0, 0, 0.589) !important;
  /* border: 1px solid rgb(252, 212, 113);
  border-left: 6px solid rgb(252, 212, 113); */
  /* border-radius: 10px !important; */
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}
.new-warn-body {
  color: white !important;
  font-weight: 500;
}
.bot-holder {
  z-index: 9999;
}
.custom-call-bg {
  width: 20% !important;
}
.selected {
  background-color: #1f79b6 !important;
  color: white !important;
}
.custom-height {
  min-height: 400px;
  max-height: 600px;
  min-width: 200px;
}
.custom-z {
  z-index: 9950;
}
.max-custom-z {
  z-index: 9999;
}
.blue_arrow {
  color: #1f79b6 !important;
}
.slide-up-animation {
  animation: slidingUp 1s linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.triangle-icon {
  color: #f9c70c !important;
}

.gm-bundled-control-on-bottom {
  margin-bottom: 60px !important;
}
.gm-fullscreen-control {
  display: none;
}
.ambassadorListHeight {
  height: 435px;
}
.channelListHeight {
  height: 465px;
}
.favoriteListWidth {
  width: 300px;
  height: 40px;
}
.fullscreen-video {
  width: 100%;
  height: 100%;
}
.customZ {
  z-index: 9999;
}
.gm-svpc {
  display: none !important;
}

.customBG {
  background: linear-gradient(
    to bottom,
    #edf2f7 0%,
    #edf2f7 50%,
    #718096 50%,
    #718096 100%
  );
}
.shadowInner {
  -moz-box-shadow: inset 0 0 5px #00000038;
  -webkit-box-shadow: inset 0 0 5px #00000038;
  box-shadow: inset 0 0 5px #00000038;
}
input[type="file"] {
  display: none;
}
.full-screen {
  height: 100% !important;
  width: 100%;
}
.not-full-screen {
  height: 100%;
  width: 100%;
}
.fullscreen-enabled {
  background: #4b5563 !important;
}
.customTextHeight {
  font-size: 50px !important;
}
.bgFavIconColor {
  color: #277ddb;
}

.video-modal .modal-dialog {
  min-width: 90%;
  max-height: 65%;
  background-color: rgba(0, 0, 0, 0.226);
  backdrop-filter: blur(10px);
}
.video-modal .modal-content {
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.226);
  /* backdrop-filter: blur(10px); */
}
.unavailable-modal .modal-dialog {
  background-color: rgba(0, 0, 0, 0.226);
  backdrop-filter: blur(10px);
}
.unavailable-modal .modal-content {
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.226);
}
.voice-modal .modal-dialog {
  background-color: rgba(0, 0, 0, 0.226);
  backdrop-filter: blur(10px);
}
.voice-modal .modal-content {
  background-color: rgba(0, 0, 0, 0.226);
  /* backdrop-filter: blur(10px); */
}

.add-blur {
  width: 100%;
  height: 100%;
  background-size: cover;
  backdrop-filter: blur(4px);
}
.just-blur {
  background-size: cover;
  backdrop-filter: blur(4px);
}
.minimized-max-height {
  max-height: 60px;
}
.video-dimensions {
  max-width: 275px;
  max-height: 225px;
  min-width: 275px;
  min-height: 225px;
  margin-bottom: 30px;
}
.video-holder {
  max-height: 600px;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.gmap-overlay {
  background-color: rgba(0, 0, 0, 0.342);
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 96%;
}
.gmnoprint {
  display: none;
}
.gm-style-iw {
  background: rgba(0, 0, 0, 0) !important;
  width: 400px;
  height: 200px;
}
.top-shadow {
  box-shadow: 0px 0 5px -2px #888;
}
.small-margin-top {
  margin-top: 21px !important;
}
.connect-fav-height {
  max-height: 100px;
}
@keyframes slidingUp {
  0% {
    bottom: -20px;
  }
  100% {
    bottom: 10px;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes draw-line {
  0% {
    width: 0;
  }
  100% {
    width: 96%;
    border-top: solid 1px rgb(206, 204, 204);
  }
}
@keyframes draw-line2 {
  0% {
    width: 0;
  }
  100% {
    width: 50%;
    border-top: solid 1px rgb(206, 204, 204);
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .safari-half-image {
      height: 50%;
    }
    .safari-quarter-image {
      height: 25%;
    }
    .safari-three-quarters-image {
      height: 75%;
    }
    .safari-full-image {
      height: 100%;
    }
  }
}
@media only screen and (max-device-width: 1024px) {
  .map-holder-chrome {
    max-height: 500px !important;
  }
  .map-holder {
    max-height: 520px !important;
  }
  .map-holder-ambassador-tablet {
    max-height: 680px !important;
  }
  .custom-tablet-login-view {
    height: 75%;
    margin-top: 5rem;
  }
}
