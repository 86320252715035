.details-container {
  display: flex;
  padding-right: 3%;
}
.details-container-flex {
  display: flex;
  flex-direction: column;
}
.details-header-container {
  display: flex;
  margin-bottom: 1rem;
  padding-left: 1rem;
}
.no-margin {
  padding: 0 !important;
}
.no-padding {
  padding-left: 0;
}
.left-details {
  width: 30%;
  max-height: 600px;
  display: flex;
  flex-direction: column;
}
.right-details {
  width: 70%;
}
.top-details {
  width: 100%;
}
.bottom-details {
  width: 100%;
  max-height: 600px;
  display: flex;
  flex-direction: column;
}

.angle {
  color: grey;
  margin-right: 10rem;
}
.angle:hover {
  cursor: pointer;
}
.details-photo-container {
  text-align: center;
  color: white;
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
}
.resource-details-image {
  max-width: 15rem;
}

.details-description-container {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.similar-cards-container {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  margin-left: 7.5%;
  padding-left: 7.5%;
  border-left: 2px solid rgba(128, 128, 128, 0.377);
}
.similar-cards-container-alt {
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

/* gets rid of scrollbar appearance */
/* width */
.similar-cards-container::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.similar-cards-container::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
.similar-cards-container::-webkit-scrollbar-thumb {
  display: none;
}

/* Handle on hover */
.similar-cards-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.resource-similar-card {
  position: relative;
  text-align: center;
  display: flex;
  width: 95%;
  margin: 2.5%;
  padding: 0.5rem;
  align-items: center;
  border-radius: 5px;
}
.resource-similar-card:hover {
  cursor: pointer;
}

.resource-description-preview {
  white-space: wrap;
  text-overflow: ellipsis;
  width: 100%;
  height: 3rem;
  display: block;
  overflow: hidden;
  color: #bcc5cf !important;
  font-size: 11px !important;
}
div.resource-description-preview span {
  color: #bcc5cf !important;
  font-size: 12px !important;
}
.resource-thumbnail-container {
  width: 100%;
  padding-left: 0.3rem;
  text-align: left;
  margin-left: 1%;
}
.fa-chevron-left:hover {
  cursor: pointer;
}
.resource-thumbnail-container {
  margin-left: 2%;
}
.resource-card-title-container {
  width: 100%;
  white-space: wrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}
.activity-title {
  max-height: 2.5rem;
  max-width: 100%;
  font-size: small;
  text-overflow: ellipsis;
}
