.resize-grid {
  padding-left: 2.7%;
}
.resource-header {
  margin-bottom: 2%;
}

.blue-text {
  color: #3498db;
}
div.activity-description-preview span {
  color: #bcc5cf !important;
  font-size: 12px !important;
}
