@media only screen and (max-width: 500px) {
  .main-div-padding-alt {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .input-width-alt {
    text-align: left;
    width: 5rem;
  }
}

@media only screen and (max-width: 600px) {
  .button-width-600 {
    width: 20%;
  }
  .margin-y-alt {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .margin-bottom-none-600 {
    margin-bottom: 0;
  }
  .padding-bottom-none-600 {
    padding-bottom: 0;
  }
  .padding-top-alt-600 {
    padding-top: 3rem;
  }
}
@media only screen and (max-width: 700px) {
  .width-increase-700 {
    width: 100%;
  }
  .login-button-alt {
    font-size: 15px;
    padding: 0.5rem;
  }
  .hidden-alt-700 {
    display: none;
  }
  .add-center-flex-alt {
    justify-content: center;
  }
  .almost-full-width-alt {
    width: 95%;
  }
}
@media only screen and (max-width: 800px) {
  .filter-options {
    max-height: 400px;
    overflow: scroll;
  }
  .less-padding {
    padding-left: 2rem;
  }
  .display-none-alt-800 {
    display: none;
  }
}
@media only screen and (max-width: 800px) {
  .display-none-alt-875 {
    display: none;
  }
}
@media only screen and (max-width: 1000px) {
  .width-full-alt {
    width: 100%;
  }
  .zero-margin-left {
    margin-left: 0;
  }

  .width-alt-1000 {
    width: 85%;
  }
}
@media only screen and (max-width: 1200px) {
  .no-margin-alt {
    margin: 0 !important;
  }
  .text-align-center-alt {
    text-align: center;
  }
}
@media only screen and (max-width: 860px) {
  .message-partner-container {
    display: none;
  }
  .display-none-alt {
    display: none;
  }
  .small-text {
    font-size: 13px;
    padding: 1rem;
    text-overflow: ellipsis;
    height: 4rem;
  }
  .smaller-padding {
    padding: 2px;
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  .messages-container-alt {
    flex-direction: column;
  }
  .channel-list-alt {
    display: flex;
    width: 100%;
    height: 5rem;
  }
  .full-width {
    width: 100%;
    padding-right: 5%;
    margin-bottom: 2%;
  }
  .card-width {
    width: 8rem;
  }
  .margin-bottom-two {
    margin-bottom: 1rem !important;
  }
  .padding-bottom-add {
    padding-bottom: 5rem;
    height: 500px;
  }
}
